.eventLabel2,.eventnsLabel1{
line-height: 15px;
}
.eventLabel2::before{

    background: rgba(254, 100, 49, 1);
}
.eventnsLabel1::before{
    background: rgba(130, 195, 88, 1);
}
.eventnsLabel1::before,.eventLabel2::before{
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
}
@media only screen and (max-width: 576px) {
.DoubleChartLabel .colXs6{
    flex: 0 0 50%;
    max-width: 50%;
}
}
@media only screen and (max-width: 420px) {
    .eventLabel2,.eventnsLabel1{
        font-size: 13px;
        line-height: 20px;
    }
  }
