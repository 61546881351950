.titleHeading {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, #1d1e2c, #64b431);
  -webkit-background-clip: text;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* padding-top: 100px; */
  text-align: center;
}
.privacyWrapper {
  background-color: #ebf7e2;
  /* padding-block: 20px; */
  position: fixed;
  left: 0;
  /* top: -20px; */
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  bottom: 0;
}
.privacyInner {
  background-color: #fafff6;
  padding: 25px;
  border-radius: 12px;
  min-height: calc(100vh - 40px);
  background: url(../assets/images/BG3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.privacyInner p {
  color: #333;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.7;
  font-weight: normal;
}

@media only screen and (max-width: 576px) {
  .titleHeading {
    font-size: 30px;
    margin-bottom: 1rem !important;
  }
}
