.loaderWrapper {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  inset: 0;
  z-index: 9999;
}
.loaderInner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* z-index: 1055; */
}
