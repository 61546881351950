.dashboardWrapper {
  background-color: #ebf7e2;
  padding-block: 20px;
}

.dashboardInner {
  background-color: #fafff6;
  padding: 30px;
  border-radius: 12px;
  min-height: calc(100vh - 40px);
  background: url(../../assets/images//BG3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.totalCardWrapper,
.sitesCardWrapper {
  border-radius: 16px;
  background: #ffc507;
  padding: 30px;
  padding-right: 170px;
  display: flex;
  align-items: center;
  column-gap: 20px;
  min-height: 180px;
  position: relative;
}

.sitesCardWrapper {
  background: #0a66c2;
}

.totalCardWrapper:after,
.sitesCardWrapper:after {
  content: "";
  background-image: url("../../assets/images/total-users.svg");
  width: 150px;
  background-size: cover;
  min-height: 180px;
  position: absolute;
  background-repeat: no-repeat;
  right: 0;
  bottom: 0;
  border-radius: 0 16px 16px 0;
}

.sitesCardWrapper:after {
  content: "";
  background-image: url("../../assets/images/sites.svg");
}

.title {
  color: #fff;
  font-size: 68.33px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  position: relative;
}

.title:after {
  content: "";
  border-bottom: 2px solid white;
  position: absolute;
  bottom: -20px;
  left: 50%;
  background: white;
  transform: translateX(-50%);
  width: 70%;
  opacity: 0.6000000238418579;
  background: #fff;
}

.description {
  color: #fff;
  font-size: 38.33px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

/* .employeeChart::before{
  content: "";
  background: url('../../assets/images/dashboardbarchatdots.png');
} */

.chartHeading {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.pieChartWrapper {
  justify-content: center;
}

.pieChartWrapper .customLabel {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.pieChartWrapper .customLabel span {
  position: relative;
}

.labelActive::before {
  background: #64b431;
}

.labelDeactivate::before {
  background: #fe6431;
}

.labelPending::before {
  background: #ffd54c;
}

.labelPending::before,
.labelActive::before,
.labelDeactivate::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  left: -17px;
  top: 7px;
}

@media only screen and (min-width: 1440px) {
  .pieChartWrapper .customLabel span {
    font-size: 26px;
    line-height: 34px;
    font-weight: 400;
    padding-bottom: 10px;
  }

  .labelActive::before,
  .labelPending::before,
  .labelDeactivate::before {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 7px;
  }
}

@media only screen and (max-width: 991px) {
  .dashboardInner {
    padding: 65px 30px 30px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .totalCardWrapper,
  .sitesCardWrapper {
    min-height: 140px;
  }
  .totalCardWrapper:after,
  .sitesCardWrapper:after {
    width: 70px;
    min-height: 140px;
  }
  .description {
    font-size: 24px;
  }
  .title {
    font-size: 30.33px;
    margin: 0;
  }
  .title::after {
    bottom: -5px;
  }
}
@media only screen and (max-width: 576px) {
  .totalCardWrapper {
    margin-bottom: 10px;
  }
  .pieChartWrapper {
    flex-direction: column;
  }
}
@media only screen and (max-width: 420px) {
  .totalCardWrapper:after,
  .sitesCardWrapper:after {
    width: 20px;
    min-height: 100px;
  }
  .totalCardWrapper,
  .sitesCardWrapper {
    padding: 12px;
    min-height: 100px;
  }
}
