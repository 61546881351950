.addNewModalMain{
  backdrop-filter: blur(2px);
}
.addNewUserWrapper {
  padding: 60px 120px;
  background: url(../../../assets/images/BG3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 12px;
}

.mainHeading {
  background: linear-gradient(180deg, #1d1e2c 0%, #64b431 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.customSelect {
  border-radius: 12px;
  background-color: #ffffff;
  border: 2px solid #1d1e2c;
  min-height: 48px;
  color: #565656;
  padding: 8px 12px;
  height: 48px;
  box-shadow: none;
  position: relative;
  appearance: auto;
}
.customSelect:focus {
  border-color: #64b431;
  outline: none;
  box-shadow: none;
  color: #64b431;
}
.addNewBody{
  padding: 0;
}
.selectOptionLabel {
  font-size: 26px;
  color: #1d1e2c;
  line-height: 39px;
  padding-left: 10px;
  margin-bottom: 5px;
  font-weight: 500;
}

.dropdownIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #999;
  transition: color 0.3s ease;
}

.signUpBtn {
  width: 100%;
  border-radius: 16px;
  background: linear-gradient(180deg, #64b431 0%, #1d1e2c 113.01%);
  font-size: 30px;
  line-height: 45px;
  color: #fff;
  border: none;
}
.passwordGrayDots {
  width: 20px;
  height: 3px;
  background: #999;
  margin-left: 2px;
}

.passwordOrangeDots {
  width: 20px;
  height: 3px;
  background: #FFB52E;
  margin-left: 2px;
}

.passwordGreenDots {
  width: 20px;
  height: 3px;
  background: #64b431;
  margin-left: 2px;
}
.eyeIcon{
  position: absolute;
  font-size: 20px;
  top: 43px;
  right: 9px;
  cursor: pointer;
}
.strongText {
  color: #64b431;
  font-size: 12px;
  line-height: 12px;
}
@media only screen and (max-width: 1500px) {
  .selectOptionLabel {
    font-size: 18px;
    line-height: 30px;
  }
  .customSelect {
    height: 47px;
  }
  .signUpBtn {
    font-size: 22px;
    line-height: 35px;
  }
  .addNewUserWrapper {
    padding: 35px;
  }
}
@media only screen and (max-width: 426px) {

  .addNewUserWrapper {
    padding: 10px;
  }
  .mainHeading{
    margin-bottom: 10px !important;
  }
}
