.userDataTable td {
  border: none;
}

.userDataTable .activityDrop::after {
  display: none !important;
}

.userDataTable .activityDrop {
  background: transparent !important;
  color: #333;
  border: transparent;
  padding: 0;
}

.userDataTable table tbody td {
  color: #1d1e2c;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  padding: 10px 5px;
}
.userDataTable table tbody .statusUser {
  border-radius: 8px;
  width: 100px;
  display: inline-block;
  text-align: center;
}

.userProfile {
  width: 40px;
  height: 40px;
}
.statusitem {
  padding: 2px 20px;
  border-radius: 30px;
}
.editIconWrapper,
.deleteIconWrapper,
.activatedIconWrapper {
  background-color: #64b431;
  padding: 2px 5px;
  border-radius: 6px;
  font-size: 12px;
  color: #fff;
}
.editIconWrapper,
.deleteIconWrapper {
  width: 30px;
  height: 25px;
}
.editIcon,
.deleteIcon {
  width: 20px;
  height: 20px;
}
.deleteIconWrapper {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.itemImage {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50px;
}

.itemName {
  width: 110px;
  height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 5px;
}

.dropdown {
  display: flex;
  flex-direction: row;
  text-align: center;
  /* gap: 2px; */
  /* padding: 5px; */
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  top: 25px;
  right: 42px;
  z-index: 1;
  /* width: 120px; */
  box-shadow: -2px 8px 20px 0px #0000002e;
}
.dropdown .dropDownItem {
  color: #64b431;
  font-size: 15px;
  line-height: 25px;
  border-bottom: 1px solid #ccc;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.dropdown .dropDownItem:hover {
  background: #64b431;
  color: #fff;
}
.dropdown .dropDownItem.dropDownItemDelete {
  border-bottom: none;
}

/* .kababMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 12px;
  width: 20px;
  cursor: pointer;
} */
.kababMenu img {
  width: 15px;
  height: 15px;
}

/* .kababMenu span {
  height: 2px;
  background-color: #000;
  border-radius: 2px;
} */

.kababMenuActive img {
  width: 15px;
}

.dropdownToggle {
  cursor: pointer;
  position: relative;
}

.kababMenuActive + .dropdown {
  top: 110%;
}
@media only screen and (min-width: 1440px) {
  /* .itemName {
    width: 100px;

  } */
}
