.userDashboardWrapper {
  background-color: #ebf7e2;
  padding-block: 20px;
}
.userDashboard {
  background-color: #fafff6;
  padding: 65px 30px 30px 30px;
  border-radius: 12px;
  min-height: calc(100vh - 40px);
  background: url(../../assets/images//BG3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.leftSide h1 {
  background: linear-gradient(180deg, #1d1e2c 0%, #64b431 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 42.33px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.logoContainer {
  /* margin-bottom: 20px; */
  display: flex;
  justify-content: center;
  width: 100%;
}

.logoContainer img {
  max-width: 150px;
  height: auto;
}

.heading {
  font-size: 24px;
  font-weight: bold;
  color: #e1ebe1;
  margin-bottom: 10px;
  text-align: center;
}

.dataTableWrapper {
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.employeeTable {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

.employeeTable th,
.employeeTable td {
  padding: 10px;
  border: 1px solid #66ff66;
  text-align: center;
}
.searchContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.searchInput {
  padding: 8px 12px;
  border: 1px solid #66ff66;
  border-radius: 4px;
  width: 250px;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: border-color 0.3s;
  border: 1px solid #1d1e2c !important;
  background-color: transparent !important;
}

.searchInput:focus {
  border-color: #004d00;
}

.employeeTable th {
  background-color: #004d00;
  color: white;
}

.employeeTable td {
  background-color: #f0f8f5;
  color: #004d00;
}
.employeeImages {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
@media (max-width: 768px) {
  .employeeTable {
    width: 100%;
  }
}
