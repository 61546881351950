.searchBar {
  display: flex;
  align-items: center;
  /* border: 1px solid #ccc; */
  background: #fff;
  box-shadow: -2px 8px 15px 0px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 4px;
}

.inputContainer {
  position: relative;
  flex-grow: 1;
}

.searchInput {
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 1rem 8px 35px;
  font-size: 14px;
  /* border-right: 1px solid #ccc; */
  position: relative;
  min-height: 48px;
}
/* .inputContainer::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 30px;
  background: #1D1E2C;
  z-index: 1;
  right: 40px;
  top: 8px;
} */

.searchIcon,
.clearIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  margin: 0 4px;
  color: #999;
}

.searchIcon {
  left: 8px;
  color: rgba(100, 180, 49, 1);
  top: 24px;
  font-size: 20px;
}

.clearIcon {
  right: 8px;
  padding: 0;
  color: rgba(100, 180, 49, 1);
}
