.containerWrapper {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  display: flex;
  padding-inline: 1rem;
}
.contactLeftSection {
  align-items: center;
  background-color: #ebf7e2;
  display: flex;
  flex: 0 0 30%;
  height: 100vh;
  justify-content: center;
  max-width: 30%;
  padding: 30px;
  position: relative;
  width: 100%;
}

.contactUsWrapper {
  padding: 20px 30px;
  /* min-height: 100vh; */
  position: relative;
  background: url(../../assets/images/BG3.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  flex-basis: calc(70% - 60px);
  flex-grow: 0;
  flex-shrink: 0;
  margin: 30px;
  max-width: calc(70% - 60px);
  padding-bottom: 15px;
  width: 100%;
}

.contactUsWrapper h3 {
  display: block;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}

.contactUsWrapper h4 {
  margin: 5px 0 15px;
  display: block;
  font-size: 13px;
  font-weight: 400;
}
.titleHeading {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, #1d1e2c, #64b431);
  -webkit-background-clip: text;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 10px;
  text-align: center;
  margin-bottom: 0;
}
.formWrapper {
  margin-inline: auto;
  max-width: 650px;
}
.formWrapper .inputField {
  color: #1d1e2c;
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
  background-color: initial;
  border-radius: 18px !important;
  font-size: 16px;
  padding-block: 12px;
  font-weight: 400;
  border: 2px solid rgba(29, 30, 44, 0.3);
  border-radius: 18px;
  min-height: 48px;
}
.formWrapper .inputField:is(:hover, :active, :focus) {
  border-color: #64b431;
  box-shadow: none;
  outline: none;
}
fieldset {
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
}

.contactUsWrapper textarea {
  height: 100px;
  max-width: 100%;
  resize: none;
}

.contactUsWrapper button[type="submit"] {
  cursor: pointer;
  width: 100%;
  border: none;
  color: #fff;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
  border: 1px solid #64b431;
  box-shadow: none;
  outline: none;
  background: linear-gradient(180deg, #64b431, #1d1e2c 75.34%);
  border: 1px solid #64b431;
  border-radius: 16px;
  color: #fff;
  font-family: Poppins;
  font-size: 30.33px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 1rem;
  width: 100%;
}

.contactUsWrapper textarea:focus {
  outline: 0;
  border: 2px solid #64b431;
}

.formWrapper .inputField::-webkit-input-placeholder {
  color: #888;
  font-size: 16px;
}

.formWrapper .inputField:-moz-placeholder {
  color: #888;
  font-size: 16px;
}

.formWrapper .inputField::-moz-placeholder {
  color: #888;
  font-size: 16px;
}

.formWrapper .inputField:-ms-input-placeholder {
  color: #888;
  font-size: 16px;
}
.presentedByText {
  bottom: 0;
  color: #1d1e2c;
  font-family: Poppins;
  font-size: 18.33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .containerWrapper {
    flex-direction: column;
  }
  .contactLeftSection {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .contactUsWrapper {
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 20px 0;
    max-width: 100%;
  }
  .titleHeading {
    padding-top: 0px;
    font-size: 26px;
  }
  .presentedByText {
    bottom: -15px;
    font-size: 16px;
  }
}
