.radioSlideButton legend {
  text-transform: capitalize;
  font-size: 16px;
}

.radioSlideButton .radio-block {
  position: relative;
  display: flex;
  width: 240px;
  border-radius: 10px;
  background-color: #f7f5f3;
}
fieldset.radioSlideButton {
  min-width: auto;
  margin-bottom: 0;
  width: 240px !important;
}
.radioSlideButton .selected {
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(50% - 1px);
  height: calc(100% - 2px);
  border-radius: 10px;
  transition: transform 0.3s;
  background-color: #64b431;
}

.radioSlideButton input[type="radio"] {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
}

.radioSlideButton label {
  position: relative;
  z-index: 100;
  width: 50%;
  padding: 6px 11px;
  transition: color 0.3s;
  text-align: center;
  cursor: pointer;
}

.radioSlideButton input[type="radio"]:checked + label {
  color: #fff;
}

.radioSlideButton input[type="radio"]:checked + .off-label + .selected {
  transform: translateX(100%);
}
