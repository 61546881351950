.userDashboardWrapper {
  background-color: #ebf7e2;
  padding-block: 20px;
}
.userDashboard {
  background-color: #fafff6;
  padding: 65px 30px 30px 30px;
  border-radius: 12px;
  min-height: calc(100vh - 40px);
  background: url(../../assets/images//BG3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.leftSide h1 {
  background: linear-gradient(180deg, #1d1e2c 0%, #64b431 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 42.33px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.width30 {
  flex: 0 0 30%;
}
.width70 {
  flex: 0 0 70%;
}
.userDetailWrapper {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  gap: 10px;
}
.userImg {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 50%;
}

.spinLoader {
  width: 1rem;
  height: 1rem;
}
