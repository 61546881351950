.otpContainer {
  max-width: 400px;
  margin: 0px auto;
  text-align: center;
  border-radius: 8px;
}

.otpInputs {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.otpField {
  width: 40px;
  height: 50px;
  font-size: 24px;
  text-align: center;
  border: 1px solid rgba(29, 30, 44, 0.3);
  border-radius: 4px;
  margin-right: 10px;
  transition: border-color 0.3s ease;
  margin: 30px 0;
}

.otpField:focus {
  border-color: #64b431;
  outline: none;
}
.descripton {
  font-size: 12px;
  color: #1d1e2c;
}
.rightSide {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.titleHeading {
  padding-top: 0;
  margin-bottom: 0;
}
.formWrapper {
  padding: 30px 0;
}
.submitBtn {
  padding: 10px 1rem;
  width: 100%;
  font-size: 20px;
  cursor: pointer;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  border-radius: 16px;
  background: linear-gradient(180deg, #64b431 0%, #1d1e2c 75.34%);
  color: #fff;
  border: 1px solid #64b431;
}

.submitBtn:hover,
.submitBtn:active,
.submitBtn:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #64b431;
}
